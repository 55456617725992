import React, { useState, useEffect, useRef, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, isSameDay } from 'date-fns';
import { Row, Card, CardBody, CardHeader, Label, Input, FormGroup, Button, Modal, Col, ModalBody, Badge, InputGroup, InputGroupAddon, InputGroupText, FormFeedback, Nav, NavItem, NavLink } from 'reactstrap';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './OrdersInformation.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleLeft, faAngleRight, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import config from '../../../config.js';
import getDecryptedAccessToken from '../../../auth/AccessToken.js';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import Select  from 'react-select';
// import AsyncSelect  from 'react-select';
// import debounce from 'lodash.debounce';

const OrderEditForm = ({ toggleEditForm, handleConfirmEdit, actualPedido, actualItemsPedido, actualCliente, actualDireccionCliente, actualBodega, clientes, direccionClientes, articulos, pedidos, bodegas }) => {
  const [clienteSeleccionado, setClienteSeleccionado] = useState('');
  const [direccionClienteSeleccionado, setDireccionClienteSeleccionado] = useState('');
  const [direccionesCliente, setDireccionesCliente] = useState(direccionClientes.filter(dir => dir.cliente_id === actualCliente.cliente_id));
  const [bodegaSeleccionada, setBodegaSeleccionada] = useState('');
  const [isEnabled, setIsEnabled] = useState(false);
  const fechaEntregaOriginal = actualPedido.fechaEntrega;
  const fechaEntregaForm = fechaEntregaOriginal ? fechaEntregaOriginal.split('T')[0] : '';

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: 'linear-gradient(to bottom, #222a42 0%, #1d253b 100%)',  // Fondo con degradado
      color: '#FFFFFFCC',            // Blanco
      fontFamily: 'Poppins, sans-serif',
      fontSize: '12px',
      borderRadius: '0.4285rem',
      transition: 'color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out',
      borderColor: state.isFocused ? '#e14eca' : '#2b3553',  // Cambia el color del borde cuando está enfocado
      '&:hover': {
        borderColor: '#2b3553' // Mantén el color del borde cuando se hace hover
      }
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#FFFFFFCC',           // Blanco con opacidad
      fontFamily: 'Poppins, sans-serif',
      fontSize: '0.7500000025rem'
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#1d253b',  // Fondo del menú desplegable
      fontFamily: 'Poppins, sans-serif',
      fontSize: '0.7500000025rem'
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#222a42' : '#1d253b', // Azul más oscuro cuando está seleccionado
      color: '#FFFFFFCC',            // Blanco
      '&:hover': {
        backgroundColor: '#222a42'  // Azul más oscuro cuando se pasa el ratón por encima
      },
      fontFamily: 'Poppins, sans-serif',
      fontSize: '0.7500000025rem'
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#FFFFFFCC',            // Blanco
      fontFamily: 'Poppins, sans-serif',
      fontSize: '0.7500000025rem'
    })
  };

  const initItems = () => {
    const itemsAux = []; // Inicializamos un array vacío para almacenar los elementos procesados
    actualItemsPedido.forEach(item => {
      // Filtramos el artículo correspondiente
      const articuloFiltered = articulos.find(art => art.articulo_id === item.articulo_id);
      // Verificamos si se encontró el artículo
      if (articuloFiltered) {
        // Agregamos el elemento al array itemsAux
        itemsAux.push({
          articulo: articuloFiltered.articulo_id,
          cantidad: item.cantidad,
          itemPedido_id: item.itempedido_id
        });
      }
    });
    return itemsAux
  };  

  const [items, setItems] = useState(initItems());

  const [selectedOptions, setSelectedOptions] = useState(
    items.map(item => ({
      value: item.articulo,
      label: articulos.find(a => a.articulo_id === item.articulo)?.idExterno || ''
    }))
  );

  const options = articulos.map(articulo => ({
    value: articulo.articulo_id,
    label: articulo.idExterno
  }));
  
  const updateIsEnabled = ( itemsSeleccionados = false ) => {
    // Evaluar todas las condiciones necesarias para habilitar el botón
    const enabled = (clienteSeleccionado && direccionClienteSeleccionado) || bodegaSeleccionada || itemsSeleccionados;
    setIsEnabled(enabled);
  };

  const handleInputChange = (index, event) => {
    const list = [...items];
    
    if (event.target) {
      // Este es el manejo para los inputs estándar (como Input de 'reactstrap')
      const { name, value } = event.target;
      list[index][name] = parseInt(value, 10);
    } else {
      // Este es el manejo para el Select de 'react-select'
      const { value } = event;
      list[index].articulo = parseInt(value, 10);
      const newSelectedOptions = [...selectedOptions];
      newSelectedOptions[index] = event;
      setSelectedOptions(newSelectedOptions);
    }

    setItems(list);
    const itemsChecked = list.every(item => item.articulo && item.cantidad);
    updateIsEnabled(itemsChecked);
  };

  const handleAddItem = () => {
    setItems([...items, { articulo: '', cantidad: '', itemPedido_id: '' }]);
    updateIsEnabled(false);
  };

  const handleRemoveItem = (index) => {
    if (items.length === 1) return; // No permitir eliminar el único ítem
    const list = [...items];
    list.splice(index, 1);
    setItems(list);
    const itemsChecked = list.every(item => item.articulo && item.cantidad);
    updateIsEnabled(itemsChecked);
  };

  const handleClienteChange = (e) => {
    const clienteId = parseInt(e.target.value);
    setClienteSeleccionado(clienteId);

    // Filtrar las direcciones relacionadas con el cliente seleccionado
    const direccionesFiltradas = direccionClientes.filter(dir => dir.cliente_id === clienteId);
    setDireccionesCliente(direccionesFiltradas);
  };

  const loadOptions = (inputValue, callback) => {
    // Simula la carga asincrónica
    setTimeout(() => {
      const filteredOptions = clientes
        .filter(cliente => cliente.nombre.toLowerCase().includes(inputValue.toLowerCase()))
        .map(cliente => ({
          value: cliente.cliente_id,
          label: cliente.nombre
        }));
      callback(filteredOptions);
    }, 1000);
  };

  const handleDireccionClienteChange = (e) => {
    const direccionClienteId = parseInt(e.target.value);
    setDireccionClienteSeleccionado(direccionClienteId);
  };

  const handleBodegaChange = (e) => {
    const bodegaId = parseInt(e.target.value);
    setBodegaSeleccionada(bodegaId);
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Evita que el formulario se envíe automáticamente
    toggleEditForm();
    
    // Verifica si bodegaSeleccionada está vacío, si es así, usa actualBodega.bodega_id
    const bodegaId = bodegaSeleccionada ? bodegaSeleccionada : actualBodega.bodega_id;
  
    // Llama a handleConfirmEdit con el valor actualizado de bodegaSeleccionada
    handleConfirmEdit(actualPedido.pedido_id, clienteSeleccionado, direccionClienteSeleccionado, items, bodegaId);
  };
  
  useEffect(() => {
    updateIsEnabled();
  }, [clienteSeleccionado, direccionClienteSeleccionado, bodegaSeleccionada]);
  
  return (
    <>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          {/* <label htmlFor="inputCustomer">Cliente</label>
          <AsyncSelect
            cacheOptions
            loadOptions={loadOptions}
            defaultOptions
            onChange={handleClienteChange}
            value={actualCliente.cliente_id}
            placeholder="Seleccionar..."
            styles={customStyles}
          /> */}
          <Label for="inputCustomer">Cliente</Label>
          <Input type="select" id="inputCustomer" name="customer" defaultValue={actualCliente.cliente_id} onChange={handleClienteChange}>
            <option value="">Seleccionar...</option>
            {clientes.map(cliente => (
              <option key={cliente.cliente_id} value={cliente.cliente_id}>{cliente.nombre}</option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="inputAddress">Dirección</Label>
          <Input type="select" id="inputAddress" name="direccion" defaultValue={actualDireccionCliente.direccioncliente_id} onChange={handleDireccionClienteChange}>
            <option>Seleccionar...</option>
            {direccionesCliente.map(direccion => (
              <option key={direccion.direccioncliente_id} value={direccion.direccioncliente_id}>{direccion.direccion}</option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="inputDepot">Bodega</Label>
          <Input type="select" id="inputDepot" name="depot" defaultValue={actualBodega.bodega_id} onChange={handleBodegaChange}>
            <option>Seleccionar...</option>
            {bodegas.map(bodega => (
              <option key={bodega.bodega_id} value={bodega.bodega_id}>{bodega.nombre}</option>
            ))}
          </Input>
        </FormGroup>
        {items.map((item, index) => (
          <div className="form-row" key={index}>
            <FormGroup className="col-md-9">
              <Label for={`inputArticle${index}`}>Artículo</Label>
              <Select
              id={`inputArticle${index}`}
              value={selectedOptions[index]}
              name="articulo" 
              onChange={(e) => handleInputChange(index, e)}
              options={options}
              placeholder="Seleccionar..."
              styles={customStyles}
              />
            </FormGroup>
            <FormGroup className="col-md-3">
              <Label for={`inputQuantity${index}`}>Cantidad</Label>
              <Input 
                type="number" 
                id={`inputQuantity${index}`} 
                name="cantidad" 
                value={item.cantidad} 
                onChange={(e) => handleInputChange(index, e)} 
              />
            </FormGroup>
            {items.length > 1 && (
              <Button color="danger" onClick={() => handleRemoveItem(index)}>Eliminar</Button>
            )}
          </div>
        ))}
        <Button color="primary" onClick={handleAddItem}>+</Button>
        <div className="d-flex justify-content-between" style={{ marginTop: '20px' }}>
          <Button color="secondary" onClick={toggleEditForm}>
            Cancelar
          </Button>{' '}
          <Button color="success" type="submit" disabled={!isEnabled}>
            Confirmar
          </Button>
        </div>
      </form>
    </>
  );
};

const OrderCreateForm = ({ toggleCreateForm, handleConfirmCreate, clientes, direccionClientes, articulos, pedidos, bodegas, despacho }) => {
  const [idPropio, setIdPropio] = useState(null);
  const [errorIdPropio, setErrorIdPropio] = useState('');
  const [items, setItems] = useState([{ articulo: '', cantidad: '' }]);
  const [clienteSeleccionado, setClienteSeleccionado] = useState(null);
  const [direccionClienteSeleccionado, setDireccionClienteSeleccionado] = useState('');
  const [direccionesCliente, setDireccionesCliente] = useState([]);
  const [bodegaSeleccionada, setBodegaSeleccionada] = useState('');
  const [selectedOptions, setSelectedOptions] = useState(
    items.map(item => ({
      value: item.articulo,
      label: articulos.find(a => a.articulo_id === item.articulo)?.idExterno || ''
    }))
  );
  
  const options = articulos.map(articulo => ({
    value: articulo.articulo_id,
    label: articulo.idExterno
  }));

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: 'linear-gradient(to bottom, #222a42 0%, #1d253b 100%)',  // Fondo con degradado
      color: '#FFFFFFCC',            // Blanco
      fontFamily: 'Poppins, sans-serif',
      fontSize: '12px',
      borderRadius: '0.4285rem',
      transition: 'color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out',
      borderColor: state.isFocused ? '#e14eca' : '#2b3553',  // Cambia el color del borde cuando está enfocado
      '&:hover': {
        borderColor: '#2b3553' // Mantén el color del borde cuando se hace hover
      }
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#FFFFFFCC',           // Blanco con opacidad
      fontFamily: 'Poppins, sans-serif',
      fontSize: '0.7500000025rem'
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#1d253b',  // Fondo del menú desplegable
      fontFamily: 'Poppins, sans-serif',
      fontSize: '0.7500000025rem'
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#222a42' : '#1d253b', // Azul más oscuro cuando está seleccionado
      color: '#FFFFFFCC',            // Blanco
      '&:hover': {
        backgroundColor: '#222a42'  // Azul más oscuro cuando se pasa el ratón por encima
      },
      fontFamily: 'Poppins, sans-serif',
      fontSize: '0.7500000025rem'
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#FFFFFFCC',            // Blanco
      fontFamily: 'Poppins, sans-serif',
      fontSize: '0.7500000025rem'
    })
  };

  const filterOptions = (candidate, inputValue) => {
    if (!inputValue) return true;
    return candidate.label.toLowerCase().includes(inputValue.toLowerCase());
  };
  
  const handleInputChange = (index, event) => {
    const list = [...items];
    
    if (event.target) {
      const { name, value } = event.target;
      list[index][name] = parseInt(value, 10);
    } else {
      const { value } = event;
      list[index].articulo = parseInt(value, 10);
      const newSelectedOptions = [...selectedOptions];
      newSelectedOptions[index] = event;
      setSelectedOptions(newSelectedOptions);
    }
  
    setItems(list);
  };

  const handleAddItem = () => {
    setItems([...items, { articulo: '', cantidad: '' }]);
  };

  const handleRemoveItem = (index) => {
    if (items.length === 1) return; // No permitir eliminar el único ítem
    const list = [...items];
    list.splice(index, 1);
    setItems(list);
  };

  const handleIdPropioChange = (e) => {
    const valorIngresado = e.target.value;

    // Verificar si el valor ingresado coincide con algún valor de pedido_id_externo
    const pedidoEncontrado = pedidos.find(pedido => pedido.pedido_id_externo === valorIngresado);
    
    if (pedidoEncontrado) {
      setErrorIdPropio('¡El ID Propio ya está en uso!');
    } else {
      setErrorIdPropio('');
    }
    setIdPropio(valorIngresado);
  };

  const handleClienteChange = (e) => {
    const clienteId = parseInt(e.target.value);
    setClienteSeleccionado(clienteId);

    // Filtrar las direcciones relacionadas con el cliente seleccionado
    const direccionesFiltradas = direccionClientes.filter(dir => dir.cliente_id === clienteId);
    setDireccionesCliente(direccionesFiltradas);
  };

  const loadOptions = (inputValue, callback) => {
    console.log('Cargando opciones:', inputValue);
    setTimeout(() => {
      const filteredOptions = clientes
        .filter(cliente => cliente.nombre.toLowerCase().includes(inputValue.toLowerCase()))
        .map(cliente => ({
          value: cliente.cliente_id,
          label: cliente.nombre
        }));
      callback(filteredOptions);
    }, 1000);
  };

  // Use useCallback to debounce loadOptions
  // const debouncedLoadOptions = useCallback(debounce(loadOptions, 300), []);

  // Obtén las primeras 1000 opciones
  const initialOptions = clientes.slice(0, 1000).map(cliente => ({
    value: cliente.cliente_id,
    label: cliente.nombre
  }));

  const handleDireccionClienteChange = (e) => {
    const direccionClienteId = parseInt(e.target.value);
    setDireccionClienteSeleccionado(direccionClienteId);
  };

  const handleBodegaChange = (e) => {
    const bodegaId = parseInt(e.target.value);
    setBodegaSeleccionada(bodegaId);
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Evita que el formulario se envíe automáticamente
    toggleCreateForm();
    handleConfirmCreate(idPropio, clienteSeleccionado, direccionClienteSeleccionado, items, bodegaSeleccionada, despacho);
  };
  const isEnabled = clienteSeleccionado && direccionClienteSeleccionado && !errorIdPropio && idPropio && bodegaSeleccionada && items.every(item => item.articulo && item.cantidad);
  useEffect(() => {
    if (bodegas.length > 0) {
      setBodegaSeleccionada(bodegas[0].bodega_id);
    }
  }, [bodegas]);
  return (
    <>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="inputIdPropio">Id Propio</Label>
          <Input type="text" id="inputIdPropio" name="idPropio" value={idPropio} onChange={handleIdPropioChange} invalid={errorIdPropio !== ''} />
          <FormFeedback style={{ color: 'red' }}>{errorIdPropio}</FormFeedback>
        </FormGroup>
        <FormGroup>
          {/* <label htmlFor="inputCustomer">Cliente</label>
          <AsyncSelect
            cacheOptions
            loadOptions={loadOptions}
            onChange={handleClienteChange}
            placeholder="Seleccionar..."
            value={clienteSeleccionado}
            options={initialOptions}
          /> */}
          <Label for="inputCustomer">Cliente</Label>
          <Input type="select" id="inputCustomer" name="customer" value={clienteSeleccionado} onChange={handleClienteChange}>
            <option value="">Seleccionar...</option>
            {clientes.map(cliente => (
              <option key={cliente.cliente_id} value={cliente.cliente_id}>{cliente.nombre}</option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="inputAddress">Dirección</Label>
          <Input type="select" id="inputAddress" name="direccion" onChange={handleDireccionClienteChange}>
            <option>Seleccionar...</option>
            {direccionesCliente.map(direccion => (
              <option key={direccion.direccioncliente_id} value={direccion.direccioncliente_id}>{direccion.direccion}</option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="inputDepot">Bodega</Label>
          <Input type="select" id="inputDepot" name="depot" onChange={handleBodegaChange} value={bodegaSeleccionada} >
            <option>Seleccionar...</option>
            {bodegas.map(bodega => (
              <option key={bodega.bodega_id} value={bodega.bodega_id}>{bodega.nombre}</option>
            ))}
          </Input>
        </FormGroup>
        {items.map((item, index) => (
          <div className="form-row" key={index}>
            <FormGroup className="col-md-9">
              <Label for={`inputArticle${index}`}>Artículo</Label>
              <Select
                id={`inputArticle${index}`}
                value={selectedOptions[index]}
                name="articulo" 
                onChange={(e) => handleInputChange(index, e)}
                options={options}
                placeholder="Seleccionar..."
                styles={customStyles}
                filterOption={filterOptions}  // Aplicar la función de filtrado aquí
              />
            </FormGroup>
            <FormGroup className="col-md-3">
              <Label for={`inputQuantity${index}`}>Cantidad</Label>
              <Input 
                type="number" 
                id={`inputQuantity${index}`} 
                name="cantidad" 
                value={item.cantidad} 
                onChange={(e) => handleInputChange(index, e)} 
              />
            </FormGroup>
            {items.length > 1 && (
              <Button color="danger" onClick={() => handleRemoveItem(index)}>Eliminar</Button>
            )}
          </div>
        ))}
        <Button color="primary" onClick={handleAddItem}>+</Button>
        <div className="d-flex justify-content-between" style={{ marginTop: '20px' }}>
          <Button color="secondary" onClick={toggleCreateForm}>
            Cancelar
          </Button>{' '}
          <Button color="success" type="submit" disabled={!isEnabled}>
            Confirmar
          </Button>
        </div>
      </form>
    </>
  );
};

const OrderDeleteForm = ({ order, toggleDeleteForm, handleConfirmDelete }) => {
  const handleSubmit = (event) => {
    event.preventDefault(); // Evita que el formulario se envíe automáticamente
    toggleDeleteForm();
    handleConfirmDelete(order.pedido_id);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <h4>
          ¿Está seguro/a que desea eliminar el pedido{" "}
          <span style={{ color: "#e14eca" }}>{order.pedido_id_externo}</span>?
        </h4>
        <div className="d-flex justify-content-between" style={{ marginTop: '20px' }}>
          <Button color="secondary" onClick={toggleDeleteForm}>
            Cancelar
          </Button>{' '}
          <Button color="danger" type="submit">
            Eliminar
          </Button>
        </div>
      </form>
    </>
  );
};

const SelectDespachoForm = ({ despachos, toggleDespachoForm, setDespachoIdActual, despachoActual }) => {
  const [filteredDespachos, setFilteredDespachos] = useState([]);
  const [selectedDate, setSelectedDate] = useState(despachoActual.fechaEntrega || new Date());
  const [showDespachos, setShowDespachos] = useState(false);

  const handleInputDespachos = (e) => {
    const valorDespacho = parseInt(e.target.value, 10);
    setDespachoIdActual(valorDespacho);
    toggleDespachoForm();
  };

  const handleDayClick = (date) => {
    setSelectedDate(date);
    setShowDespachos(true);
  };

  useEffect(() => {
    if (selectedDate) {
      const formattedDate = format(selectedDate, 'yyyy-MM-dd');
      const filtered = despachos.filter(despacho => despacho.fechaEntrega.startsWith(formattedDate));
      setFilteredDespachos(filtered);
    } else {
      setFilteredDespachos([]);
    }
  }, [selectedDate, despachos]);

  const isDespachoDay = (date) => {
    const formattedDate = format(date, 'yyyy-MM-dd');
    return despachos.some(despacho => despacho.fechaEntrega.startsWith(formattedDate));
  };

  const dayClassName = (date) => {
    if (isDespachoDay(date) && isSameDay(date, selectedDate)) {
      return 'selected-despacho-day';
    } else if (isDespachoDay(date)) {
      return 'despacho-day';
    } else {
      return undefined;
    }
  };
  

  return (
    <form className="form-container">
      <div className="calendar-container">
        <DatePicker
          selected={selectedDate}
          onChange={date => handleDayClick(date)}
          dateFormat="yyyy-MM-dd"
          inline
          dayClassName={dayClassName}
        />
      </div>
      
      {showDespachos && (
        <FormGroup>
          <Input type="select" name="selectDespacho"
            id="selectDespacho" 
            onChange={handleInputDespachos} 
          >
            <option value="">Seleccionar...</option>
            {filteredDespachos.map(despacho => (

              <option key={despacho.despacho_id} value={despacho.despacho_id}>
                {despacho.nombre}
              </option>
            ))}
          </Input>
        </FormGroup>
      )}
    </form>
  );
};

const DespachoCreateForm = ({ toggleDespachoCreateForm, handleDespachoCreate }) => {
  const obtenerFechaActual = () => {
    const fecha = new Date();
    const dia = fecha.getDate().toString().padStart(2, '0');
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
    const año = fecha.getFullYear();
    return `${año}-${mes}-${dia}`;
  };
  
  const obtenerTimestamp = () => {
    const fecha = new Date();
    const año = fecha.getFullYear();
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
    const dia = fecha.getDate().toString().padStart(2, '0');
    const hora = fecha.getHours().toString().padStart(2, '0');
    const minutos = fecha.getMinutes().toString().padStart(2, '0');
    const segundos = fecha.getSeconds().toString().padStart(2, '0');
    return `${año}-${mes}-${dia} ${hora}:${minutos}:${segundos}`;
  };  
  
  const fechaActual = obtenerFechaActual();
  const nombreInicial = `Planeación ${obtenerTimestamp()}`;

  console.log(fechaActual);
  console.log(nombreInicial);
  
  const [fechaEntrega, setFechaEntrega] = useState(fechaActual);
  const [nombre, setNombre] = useState(nombreInicial);
  

  const handleSubmit = (event) => {
    event.preventDefault(); // Evita que el formulario se envíe automáticamente
    toggleDespachoCreateForm();
    handleDespachoCreate(nombre, fechaEntrega);
  };
  const isEnabled = nombre && fechaEntrega;

  return (
    <>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="inputName">Nombre</Label>
          <Input type="text" id="inputName" name="nombre" value={nombre} onChange={(e) => setNombre(e.target.value)} />
        </FormGroup>
        <FormGroup>
          <Label for="date">Fecha de Entrega</Label>
          <Input type="date" id="inputDeliveryDate" name="deliveryDate" value={fechaEntrega} onChange={(e) => setFechaEntrega(e.target.value)} />
        </FormGroup>
        <div className="d-flex justify-content-between" style={{ marginTop: '20px' }}>
          <Button color="secondary" onClick={toggleDespachoCreateForm}>
            Cancelar
          </Button>{' '}
          <Button color="success" type="submit" disabled={!isEnabled}>
            Confirmar
          </Button>
        </div>
      </form>
    </>
  );
};

const OrdersInformation = ({ despachos, nombreDespacho, setNombreDespacho, despachoActual, despachoIdActual, setDespachoIdActual, pedidos, clientes, direccionClientes, articulos, itemPedidos, bodegas, setSelectedAccuracys, selectedAccuracys, setSelectedPuntosIds, selectedPuntosIds, showButtons, setShowButtons, markerInitialPositions, setMarkerInitialPositions, markerPositions, setMarkerPositions, showPuntosNuevos, setShowPuntosNuevos, uploading, setUploading, numPages, actualPage, setActualPage, filtroNombre, setFiltroNombre, handleExport }) => {

  const accuracyGroups = {};
  const [openMenus, setOpenMenus] = useState({});
  const [hoveredCards, setHoveredCards] = useState({});
  const [modalEdit, setModalEdit] = useState(false);
  const [modalCreate, setModalCreate] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalDespacho, setModalDespacho] = useState(false);
  const [modalDespachoCreate, setModalDespachoCreate] = useState(false);
  const [pedidoId, setPedidoId] = useState(null);
  const [orderInformation, setOrderInformation] = useState(null);
  const [itemsOrderInformation, setItemsOrderInformation] = useState([]);
  const [customerInformation, setCustomerInformation] = useState(null);
  const [customerAddressInformation, setCustomerAddressInformation] = useState(null);
  const [depotInformation, setDepotInformation] = useState(null);
  const [orderInformationEdit, setOrderInformationEdit] = useState(new Set());
  const [focused, setFocused] = useState(false);
  const menuRef = useRef(null);
  const accessToken = getDecryptedAccessToken();
  const navigate = useNavigate();

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        // Cerrar el menú si se hace clic fuera de él
        setOpenMenus({});
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  let filteredData = selectedPuntosIds.size > 0
    ? pedidos.filter(item => selectedPuntosIds.has(item.pedido_id))
    : pedidos;

  filteredData = showPuntosNuevos
    ? filteredData.filter(item => item.pedido_pendiente)
    : filteredData;

  filteredData = despachoIdActual
    ? filteredData.filter(item => item.despacho_id === despachoIdActual)
    : filteredData;

  if (filtroNombre) {
    filteredData = filteredData.filter(item =>
      item.nombre.toLowerCase().includes(filtroNombre.toLowerCase()) ||
      item.direccion.toLowerCase().includes(filtroNombre.toLowerCase()) ||
      item.pedido_id_externo.toLowerCase().includes(filtroNombre.toLowerCase())
    );
  } else {
    filteredData = filteredData.slice(
      actualPage * 100,
      (actualPage + 1) * 100
    );
  };

  filteredData.forEach((item) => {
    item.color = '#5e72e4';
  });

  const handlePuntoIdClick = (puntoId) => {
    setShowButtons(false);
    if (selectedPuntosIds.has(puntoId)) {
      setSelectedPuntosIds(new Set());
    } else {
      setSelectedPuntosIds(new Set([puntoId]));
    }
  };

  const handlePuntosNuevosChange = () => {
    setShowPuntosNuevos(!showPuntosNuevos);
    setSelectedAccuracys(new Set());
    setSelectedPuntosIds(new Set());
  };

  const handleFiltroNombreChange = (event) => {
    setFiltroNombre(event.target.value);
  };

  const handleMenuClick = (pedido_id) => {
    setOpenMenus(prevState => ({
      ...prevState,
      [pedido_id]: !prevState[pedido_id]
    }));
  };

  const handleEditClick = (pedido_id) => {
    setOpenMenus(prevState => ({
      ...prevState,
      [pedido_id]: !prevState[pedido_id]
    }));
    setPedidoId(pedido_id);
    const orderFiltered = filteredData.filter(item =>
      item.pedido_id === pedido_id
    );
    const itemsOrderFiltered = itemPedidos.filter(item =>
      item.pedido_id === pedido_id
    );
    const customerAddressFiltered = direccionClientes.filter(item =>
      item.direccioncliente_id === orderFiltered[0].direccioncliente_id
    );
    const customerFiltered = clientes.filter(item =>
      item.cliente_id === customerAddressFiltered[0].cliente_id
    );
    const depotFiltered = bodegas.filter(item =>
      item.bodega_id === itemsOrderFiltered[0].bodega_id /* REVISAR ESTO SI BODEGA DE PASA A NIVEL DE PEDIDO*/
    );

    setOrderInformation(orderFiltered[0]);
    setItemsOrderInformation(itemsOrderFiltered);
    setCustomerInformation(customerFiltered[0]);
    setCustomerAddressInformation(customerAddressFiltered[0]);
    setDepotInformation(depotFiltered[0]);
    setModalEdit(true);
  };

  const handleCreateClick = () => {
    setModalCreate(true);
  };

  const handleDeleteClick = (pedido_id) => {
    setOpenMenus(prevState => ({
      ...prevState,
      [pedido_id]: !prevState[pedido_id]
    }));
    setPedidoId(pedido_id);
    const orderFiltered = filteredData.filter(item =>
      item.pedido_id === pedido_id
    );
    setOrderInformation(orderFiltered[0]);
    setModalDelete(true);
  };

  const handleDespachoClick = () => {
    setModalDespacho(true);
  };

  const handleConfirmEdit = (pedido_id, clienteSeleccionado, direccionClienteSeleccionado, items, bodegaSeleccionada) => {
    setUploading(true);
    const apiUrl = `${config.apiBaseUrl}/EditarPedido/`;
    const postData = {
      'pedido_id':pedido_id,
      'cliente':clienteSeleccionado,
      'direccionCliente':direccionClienteSeleccionado,
      'itemsPedido':items,
      'bodega':bodegaSeleccionada
    };
    // Verifica si se ha almacenado un token de acceso en localStorage
    if (!accessToken) {
      console.error('No se encontró un token de acceso en localStorage');
      return;
    }
  
    // Configura los encabezados para incluir el token de acceso como Bearer
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
  
    // Realiza la solicitud Axios con los encabezados configurados
    axios.post(apiUrl, postData, { headers })
      .then(response => {
        if (response.status === 200) {
          window.location.reload();
        } else if (response.status === 401) {
          console.error('Token de acceso inválido o expirado');
          navigate(`/login`);
          throw new Error('Token de acceso inválido o expirado');
        } else {
          console.error('Error en la respuesta del servidor:', response.data.message);
        }
      })
      .catch(error => {
        navigate(`/login`);
        console.error('Error al realizar la solicitud:', error);
      });
  }  

  const handleConfirmCreate = (idPropio, cliente, direccionCliente, itemsPedido, bodega, despacho) => {
    setUploading(true);
    const apiUrl = `${config.apiBaseUrl}/CrearPedido/`;
    const postData = {
      'idPropioPedido':idPropio,
      'cliente':cliente,
      'direccionCliente':direccionCliente,
      'itemsPedido':itemsPedido,
      'bodega': bodega,
      'despacho_id': despacho
    };
    // Verifica si se ha almacenado un token de acceso en localStorage
    if (!accessToken) {
      console.error('No se encontró un token de acceso en localStorage');
      return;
    }
  
    // Configura los encabezados para incluir el token de acceso como Bearer
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
  
    // Realiza la solicitud Axios con los encabezados configurados
    axios.post(apiUrl, postData, { headers })
      .then(response => {
        if (response.status === 200) {
          window.location.reload();
        } else if (response.status === 401) {
          console.error('Token de acceso inválido o expirado');
          navigate(`/login`);
          throw new Error('Token de acceso inválido o expirado');
        } else {
          console.error('Error en la respuesta del servidor:', response.data.message);
        }
      })
      .catch(error => {
        navigate(`/login`);
        console.error('Error al realizar la solicitud:', error);
      });
  } 

  const handleConfirmDelete = (pedido_id) => {
    setUploading(true);
    const apiUrl = `${config.apiBaseUrl}/EliminarPedido/`;
    const postData = {
      'pedido_id':pedido_id
    };
    // Verifica si se ha almacenado un token de acceso en localStorage
    if (!accessToken) {
      console.error('No se encontró un token de acceso en localStorage');
      return;
    }
  
    // Configura los encabezados para incluir el token de acceso como Bearer
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
  
    // Realiza la solicitud Axios con los encabezados configurados
    axios.post(apiUrl, postData, { headers })
      .then(response => {
        if (response.status === 200) {
          window.location.reload();
        } else if (response.status === 401) {
          console.error('Token de acceso inválido o expirado');
          navigate(`/login`);
          throw new Error('Token de acceso inválido o expirado');
        } else {
          console.error('Error en la respuesta del servidor:', response.data.message);
        }
      })
      .catch(error => {
        navigate(`/login`);
        console.error('Error al realizar la solicitud:', error);
      });
  }  

  const handleConfirmDespacho = (despacho_id) => {  
    // dataInputRuteo(despacho_id);
    console.log(despacho_id);
  }  

  const toggleEditForm = () => {
    // Alternar el estado del modal
    setModalEdit(!modalEdit);
  };
  
  const toggleCreateForm = () => {
    // Alternar el estado del modal
    setModalCreate(!modalCreate);
  };

  const toggleDeleteForm = () => {
    // Alternar el estado del modal
    setModalDelete(!modalDelete);
  };

  const toggleDespachoForm = () => {
    // Alternar el estado del modal
    setModalDespacho(!modalDespacho);
  };

  const toggleDespachoCreateForm = () => {
    // Alternar el estado del modal
    setModalDespachoCreate(!modalDespachoCreate);
  };

  const handleCardMouseEnter = (pedido_id) => {
    setHoveredCards(prevState => ({
      ...prevState,
      [pedido_id]: true
    }));
  };
  
  const handleCardMouseLeave = (pedido_id) => {
    setHoveredCards(prevState => ({
      ...prevState,
      [pedido_id]: false
    }));
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const goToFirstPage = () => {
    setActualPage(0);
  };

  const goToPrevPage = () => {
    setActualPage(Math.max(actualPage - 1, 0));
  };

  const goToNextPage = () => {
    setActualPage(Math.min(actualPage + 1, numPages - 1));
  };

  const goToLastPage = () => {
    setActualPage(numPages - 1);
  };

  const handleChangeNombreDespacho = (event) => {
    setNombreDespacho(event.target.value);
  };

  const handleDespachoCreate = (nombre, fechaEntrega) => {
    setUploading(true);
    const apiUrl = `${config.apiBaseUrl}/CrearDespacho/`;
    const postData = {
      'nombre':nombre,
      'fechaEntrega':fechaEntrega
    };
    // Verifica si se ha almacenado un token de acceso en localStorage
    if (!accessToken) {
      console.error('No se encontró un token de acceso en localStorage');
      return;
    }
  
    // Configura los encabezados para incluir el token de acceso como Bearer
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
  
    // Realiza la solicitud Axios con los encabezados configurados
    axios.post(apiUrl, postData, { headers })
      .then(response => {
        if (response.status === 200) {
          window.location.reload();
        } else if (response.status === 401) {
          console.error('Token de acceso inválido o expirado');
          navigate(`/login`);
          throw new Error('Token de acceso inválido o expirado');
        } else {
          console.error('Error en la respuesta del servidor:', response.data.message);
        }
      })
      .catch(error => {
        navigate(`/login`);
        console.error('Error al realizar la solicitud:', error);
      });
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div>
        <div className="title-container">
          <h2 className="text-center mb-3 text-white" style={{ paddingTop: '1rem', paddingBottom: '5px' }}>GESTIÓN PEDIDOS</h2>
          <div className="divider-line"></div>
        </div>
        <Modal isOpen={modalEdit} toggle={toggleEditForm} modalClassName="modal-black">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={toggleEditForm}>
              <i className="tim-icons icon-simple-remove"></i>
            </button>
            <h5 className="modal-title">Editar Pedido</h5>
          </div>
          <ModalBody>
            <OrderEditForm toggleEditForm={toggleEditForm} handleConfirmEdit={handleConfirmEdit} actualPedido={orderInformation} actualItemsPedido={itemsOrderInformation} actualCliente={customerInformation} actualDireccionCliente={customerAddressInformation} actualBodega={depotInformation} clientes={clientes} direccionClientes={direccionClientes} articulos={articulos} pedidos={pedidos} bodegas={bodegas} />
          </ModalBody>
        </Modal>
        <Modal isOpen={modalCreate} toggle={toggleCreateForm} modalClassName="modal-black">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={toggleCreateForm}>
              <i className="tim-icons icon-simple-remove"></i>
            </button>
            <h5 className="modal-title">Crear Pedido</h5>
          </div>
          <ModalBody>
            <OrderCreateForm toggleCreateForm={toggleCreateForm} handleConfirmCreate={handleConfirmCreate} clientes={clientes} direccionClientes={direccionClientes} articulos={articulos} pedidos={pedidos} bodegas={bodegas} despacho={despachoIdActual} />
          </ModalBody>
        </Modal>
        <Modal isOpen={modalDelete} toggle={toggleDeleteForm} modalClassName="modal-black">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={toggleDeleteForm}>
              <i className="tim-icons icon-simple-remove"></i>
            </button>
            <h5 className="modal-title">Eliminar Pedido</h5>
          </div>
          <ModalBody>
            <OrderDeleteForm order={orderInformation} toggleDeleteForm={toggleDeleteForm} handleConfirmDelete={handleConfirmDelete} />
          </ModalBody>
        </Modal>
        <Modal isOpen={modalDespacho} toggle={toggleDespachoForm} modalClassName="modal-black">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={toggleDespachoForm}>
              <i className="tim-icons icon-simple-remove"></i>
            </button>
            <h5 className="modal-title">Seleccionar Despacho</h5>
          </div>
          <ModalBody>
            <SelectDespachoForm despachos={despachos} toggleDespachoForm={toggleDespachoForm} setDespachoIdActual={setDespachoIdActual} despachoActual={despachoActual} />
          </ModalBody>
        </Modal>
        <Modal isOpen={modalDespachoCreate} toggle={toggleDespachoCreateForm} modalClassName="modal-black">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={toggleDespachoCreateForm}>
              <i className="tim-icons icon-simple-remove"></i>
            </button>
            <h5 className="modal-title">Crear Despacho</h5>
          </div>
          <ModalBody>
            <DespachoCreateForm toggleDespachoCreateForm={toggleDespachoCreateForm} handleDespachoCreate={handleDespachoCreate} />
          </ModalBody>
        </Modal>
        <Card className="mb-3" style={{ marginTop: '10px' }}>
          <CardHeader>
            <Nav className="justify-content-center">
                <NavItem>
                    <NavLink disabled href="#">Pedidos</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate('/admin/customers')}
                    >
                        Clientes
                    </NavLink>
                </NavItem>
            </Nav>
            <FormGroup className="custom-input-group">
              <Input type="text" name="tittle" id="tittle" value={nombreDespacho} onChange={handleChangeNombreDespacho}/>
            </FormGroup>
            <div className="form-row d-flex align-items-top">
              <div className="col-md-6">
                <InputGroup className={focused ? "input-group-focus" : ""}>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="tim-icons icon-zoom-split"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    placeholder="Buscar por nombre o dirección"
                    onChange={handleFiltroNombreChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                </InputGroup>
              </div>
              <div className="col-md-2">
                <Button className="btn-icon w-100" color="success" onClick={() => toggleDespachoCreateForm()}>
                  <i className="fa-solid fa-plus" />
                </Button>
              </div>
              <div className="col-md-2">
                <Button className="btn-icon w-100" color="info" onClick={() => handleDespachoClick()}>
                  <i className="fa-solid fa-calendar-days" />
                </Button>
              </div>
              <div className="col-md-2">
                <Button className="btn-icon w-100" color="warning" onClick={() => handleExport(despachoIdActual)}>
                  <i className="fa-solid fa-download"></i>
                </Button>
              </div>
            </div>
            {/* <div style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <InputGroup className={focused ? "input-group-focus" : ""}>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText><i className="tim-icons icon-zoom-split"></i></InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    placeholder="Buscar por nombre o dirección"
                    onChange={handleFiltroNombreChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                </InputGroup>
              </div>
              <div>
                <FormGroup check>
                  <Label className="form-check-label">
                    <Input className="form-check-input" type="checkbox" value="" onClick={handlePuntosNuevosChange} defaultChecked={false} />
                      Pedidos Pendientes
                    <span className="form-check-sign">
                      <span className="check"></span>
                    </span>
                  </Label>
                </FormGroup>
              </div>
            </div> */}
            <Button className="w-100" color="info" title="Agregar Nuevo Pedido" onClick={() => handleCreateClick()}>
              Agregar Nuevo Pedido
            </Button>
          </CardHeader>
          <CardBody>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <FontAwesomeIcon icon={faAngleDoubleLeft} onClick={goToFirstPage} style={{ cursor: 'pointer' }} />
                <FontAwesomeIcon icon={faAngleLeft} onClick={goToPrevPage} style={{ cursor: 'pointer' }} />
              </div>
              <span>Página {actualPage + 1} de {numPages}</span>
              <div>
                <FontAwesomeIcon icon={faAngleRight} onClick={goToNextPage} style={{ cursor: 'pointer' }} />
                <FontAwesomeIcon icon={faAngleDoubleRight} onClick={goToLastPage} style={{ cursor: 'pointer' }} />
              </div>
            </div>
            {filteredData.map(item => (
              <div key={item.pedido_id} onMouseEnter={() => handleCardMouseEnter(item.pedido_id)} onMouseLeave={() => handleCardMouseLeave(item.pedido_id)}>
                <Card className="mb-2" style={{ display: 'flex' }}>
                  <CardHeader style={{ display: 'flex' }}>
                    <div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <i className="fa-solid fa-box" style={{ marginRight: '8px', color: item.color, fontSize: '0.8rem' }}></i>
                        <span>{item.pedido_id_externo}</span>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <i className="fa-solid fa-user" style={{ marginRight: '8px', color: item.color, fontSize: '0.8rem' }}></i>
                        <span>{item.nombre}</span>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <i className="fa-solid fa-map-pin" style={{ marginRight: '8px', color: item.color, fontSize: '1rem' }}></i>
                        <span>{item.direccion}</span>
                      </div>
                    </div>
                    <div style={{ position: 'relative' }} ref={menuRef}>
                      <i className={`ml-auto fas ${selectedPuntosIds.has(item.pedido_id) ? 'fa-solid fa-circle' : 'fa-regular fa-circle'}`} style={{ fontSize: '1rem', color: selectedPuntosIds.has(item.pedido_id) ? '#2bffc6' : 'inherit', cursor: 'pointer' }} onClick={() => handlePuntoIdClick(item.pedido_id)} />
                      {hoveredCards[item.pedido_id] &&
                        <div
                          style={{
                            position: 'absolute',
                            top: '2.5rem',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '1.5rem', // Tamaño más grande que el icono
                            height: '1rem', // Tamaño más grande que el icono
                            cursor: 'pointer',
                            backgroundColor: 'transparent' // Hacer el área transparente
                          }}
                          onClick={() => handleMenuClick(item.pedido_id)} onMouseDown={(e) => e.stopPropagation()}
                        ></div>
                      }
                      {hoveredCards[item.pedido_id] &&
                        <i
                          className="fas fa-ellipsis-v"
                          style={{
                            fontSize: '1.3rem',
                            position: 'absolute',
                            top: '2.5rem', // Colocar el icono debajo del círculo con un margen adecuado
                            left: '50%', // Colocar el icono en el centro horizontalmente
                            transform: 'translate(-50%, -50%)', // Centrar verticalmente y horizontalmente
                            cursor: 'pointer'
                          }}
                          onClick={() => handleMenuClick(item.pedido_id)} onMouseDown={(e) => e.stopPropagation()}
                        ></i>

                      }
                      {openMenus[item.pedido_id] && (
                        <div style={{ position: 'absolute', top: '3.5rem', right: '0', backgroundColor: 'white', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.08)', zIndex: 9999 }}>
                          <div className='div_hover' style={{ padding: '8px', cursor: 'pointer' }} onClick={() => handleEditClick(item.pedido_id)} onMouseDown={(e) => e.stopPropagation()} /* Detener la propagación del evento */>Editar</div>
                          <div className='div_hover' style={{ padding: '8px', cursor: 'pointer' }} onClick={() => handleDeleteClick(item.pedido_id)} onMouseDown={(e) => e.stopPropagation()} /* Detener la propagación del evento */>Eliminar</div>
                        </div>
                      )}
                    </div>
                  </CardHeader>
                  <CardBody>
                    <Badge color="info" className="ml-2">
                      {new Date(item.fechaEntrega).toISOString().split('T')[0]}
                    </Badge>
                    <Badge color="info" className="ml-2">
                      {item.total_items} Items
                    </Badge>
                    <Badge color="info" className="ml-2">
                      {item.total_peso ? `${item.total_peso.toFixed(2)}  kg` : ''}
                    </Badge>
                  </CardBody>
                </Card>
              </div>
            ))}
          </CardBody>
        </Card>
      </div>
    </DndProvider>
  );
};  
    
export default OrdersInformation;
