import axios from "axios";
import getDecryptedAccessToken from "../../../auth/AccessToken";
import config from "../../../config";

const accessToken = getDecryptedAccessToken();

export const deletePoligonoRestriccionVehiculoCliente = async (poligonoId, navigate) => {
    const apiUrl = `${config.apiBaseUrl}/EliminarPoligonoRestriccionVehiculoCliente/${poligonoId}/`;

    // Verifica si se ha almacenado un token de acceso en localStorage
    if (!accessToken) {
        console.error('No se encontró un token de acceso en localStorage');
        return;
    }

    // Configura los encabezados para incluir el token de acceso como Bearer
    const headers = {
        Authorization: `Bearer ${accessToken}`,
    };

    try {
        const response = await axios.delete(apiUrl, { headers });
        if (response.status === 200) {
            console.log("Polígono eliminado con éxito");
            window.location.reload(); // Recargar la página si la eliminación fue exitosa
            return true;
        } else if (response.status === 401) {
            console.error('Token de acceso inválido o expirado');
            navigate(`/login`);
            throw new Error('Token de acceso inválido o expirado');
        } else {
            console.error('Error en la respuesta del servidor:', response.data.message);
            throw new Error(response.data.message);
        }
    } catch (error) {
        navigate(`/login`);
        console.error('Error al realizar la solicitud:', error);
        throw error;
    }
};
