import React, { useState, useEffect, useRef } from 'react';
import classnames from "classnames";
import moment from 'moment';
import { Collapse, Card, CardBody, CardHeader, Label, Input, FormGroup, Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupAddon, InputGroupText, FormFeedback, Nav, NavItem, NavLink, Row } from 'reactstrap';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './CustomersInformation.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleLeft, faAngleRight, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import config from '../../../config.js';
import getDecryptedAccessToken from '../../../auth/AccessToken.js';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const CustomerEditForm = ({ customer, customerAddresses, toggleEditForm, handleConfirmEdit }) => {
  const [nombre, setNombre] = useState(null);
  const [horaMin, setHoraMin] = useState(customer.horaMinima);
  const [horaMax, setHoraMax] = useState(customer.horaMaxima);
  const [horaError, setHoraError] = useState('');
  const [isEnabled, setIsEnabled] = useState(false);
  const filteredAddresses = customerAddresses.filter(item => item.cliente_id === customer.cliente_id);

  const initAddresses = () => {
    const addressessAux = []; // Inicializamos un array vacío para almacenar los elementos procesados
    filteredAddresses.forEach(item => {
      addressessAux.push({
        direccioncliente_id: item.direccioncliente_id,
        celular: item.telefono,
        direccion: item.direccion_cliente,
        ciudad: item.ciudad,
        departamento: item.departamento,
        pais: item.pais
      });
    });
    return addressessAux
  };  

  const [addresses, setAddresses] = useState(initAddresses());

  const updateIsEnabled = ( direccionesSeleccionadas = false ) => {
    // Evaluar todas las condiciones necesarias para habilitar el botón
    const enabled = nombre || direccionesSeleccionadas;
    setIsEnabled(enabled);
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Evita que el formulario se envíe automáticamente
    toggleEditForm();
    handleConfirmEdit(
      customer.cliente_id,
      nombre,
      horaMin,
      horaMax,
      addresses
    );
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const list = [...addresses];
    list[index][name] = value;
    setAddresses(list);
    const itemsChecked = list.every(item => item.celular && item.direccion && item.ciudad && item.departamento && item.pais);
    updateIsEnabled(itemsChecked);
  };

  const handleAddAddress = () => {
    setAddresses([...addresses, { direccioncliente_id: '', celular: '', direccion: '', ciudad: '', departamento: '', pais: '' }]);
    updateIsEnabled(false);
  };

  const handleRemoveAddress = (index) => {
    if (addresses.length === 1) return; // No permitir eliminar el único ítem
    const list = [...addresses];
    list.splice(index, 1);
    setAddresses(list);
    const itemsChecked = list.every(item => item.direccioncliente_id && item.celular && item.direccion && item.ciudad && item.departamento && item.pais);
    updateIsEnabled(itemsChecked);
  };

  const handleHoraMinChange = (e) => {
    const nuevaHoraMin = e.target.value;
    setHoraMin(nuevaHoraMin);
    if (nuevaHoraMin <= horaMax) {
      // setHoraMin(nuevaHoraMin);
      setHoraError(''); // Limpia el mensaje de alerta si la hora mínima es válida
      updateIsEnabled(true);
    } else {
      setHoraError('La hora mínima debe ser menor o igual a la hora máxima');
      updateIsEnabled(false);
    }
  };

  const handleHoraMaxChange = (e) => {
    const nuevaHoraMax = e.target.value;
    setHoraMax(nuevaHoraMax);
    if (nuevaHoraMax >= horaMin) {
      // setHoraMax(nuevaHoraMax);
      setHoraError(''); // Limpia el mensaje de alerta si la hora máxima es válida
      updateIsEnabled(true);
    } else {
      setHoraError('La hora máxima debe ser mayor o igual a la hora mínima');
      updateIsEnabled(false);
    }
  };
  
  useEffect(() => {
    updateIsEnabled();
  }, [nombre]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="inputNombre">Nombre</Label>
          <Input type="text" id="inputName" name="nombre" defaultValue={customer.nombre} onChange={(e) => { setNombre(e.target.value) }} />
        </FormGroup>
        <div className="form-row">
          <FormGroup className="col-md-6">
            <Label for="inputHoraMinimaVisita">Hora Mínima Visita</Label>
            <Input
              type="time"
              id="inputHoraMinimaVisita"
              name="horaMinimaVisita"
              value={horaMin}
              onChange={handleHoraMinChange}
            />
          </FormGroup>
          <FormGroup className="col-md-6">
            <Label for="inputHoraMaximaVisita">Hora Máxima Visita</Label>
            <Input
              type="time"
              id="inputHoraMaximaVisita"
              name="horaMaximaVisita"
              value={horaMax}
              onChange={handleHoraMaxChange}
            />
          </FormGroup>
        </div>
        {horaError && <div className="text-danger">{horaError}</div>}
        {addresses.map((item, index) => (
          <div key={index}>
            <FormGroup>
              <Label for="inputPhone">Celular</Label>
              <Input type="tel" id="inputPhone" name="celular" value={item.celular} onChange={(e) => { handleInputChange(index, e) }} />
            </FormGroup>
            <div className="form-row">
              <FormGroup className="col-md-6">
                <Label for={`inputAddress${index}`}>Dirección</Label>
                <Input type="text" id={`inputAddress${index}`} name="direccion" value={item.direccion} onChange={(e) => { handleInputChange(index, e) }} />
              </FormGroup>
              <FormGroup className="col-md-2">
                <Label for={`inputCity${index}`}>Ciudad</Label>
                <Input type="text" id={`inputCity${index}`} name="ciudad" value={item.ciudad} onChange={(e) => { handleInputChange(index, e) }} />
              </FormGroup>
              <FormGroup className="col-md-2">
                <Label for={`inputState${index}`}>Departamento</Label>
                <Input type="text" id={`inputState${index}`} name="departamento" value={item.departamento} onChange={(e) => { handleInputChange(index, e) }} />
              </FormGroup>
              <FormGroup className="col-md-2">
                <Label for={`inputCountry${index}`}>País</Label>
                <Input type="text" id={`inputCountry${index}`} name="pais" value={item.pais} onChange={(e) => { handleInputChange(index, e) }} />
              </FormGroup>
              {addresses.length > 1 && (
                <Button color="danger" onClick={() => handleRemoveAddress(index)}>Eliminar</Button>
              )}
            </div>
          </div>
        ))}
        <Button color="primary" onClick={handleAddAddress}>+</Button>
        <div className="d-flex justify-content-between" style={{ marginTop: '20px' }}>
          <Button color="secondary" onClick={toggleEditForm}>
            Cerrar
          </Button>{' '}
          <Button color="success" type="submit" disabled={!isEnabled}>
            Confirmar
          </Button>
        </div>
      </form>
    </>
  );
};

const CustomerCreateForm = ({ customers, toggleCreateForm, handleConfirmCreate }) => {
  const [nombre, setNombre] = useState(null);
  const [horaMin, setHoraMin] = useState("00:00:00");
  const [horaMax, setHoraMax] = useState("23:59:00");
  const [idPropio, setIdPropio] = useState(null);
  const [horaError, setHoraError] = useState('');
  const [isEnabled, setIsEnabled] = useState(false);
  const [errorIdPropio, setErrorIdPropio] = useState('');
  const [addresses, setAddresses] = useState([{ celular: '', direccion: '', ciudad: '', departamento: '', pais: '' }]);

  const handleSubmit = (event) => {
    event.preventDefault(); // Evita que el formulario se envíe automáticamente
    toggleCreateForm();
    handleConfirmCreate(nombre,idPropio,horaMin,horaMax,addresses);
  };

  const updateIsEnabled = ( direccionesSeleccionadas = true ) => {
    const itemsChecked = addresses.every(item => item.celular && item.direccion && item.ciudad && item.departamento && item.pais && !errorIdPropio);
    // Evaluar todas las condiciones necesarias para habilitar el botón
    const enabled = nombre && idPropio && itemsChecked && direccionesSeleccionadas && itemsChecked;
    setIsEnabled(enabled);
  };

  const handleIdPropioChange = (e) => {
    const valorIngresado = e.target.value;

    // Verificar si el valor ingresado coincide con algún valor de pedido_id_externo
    const clienteEncontrado = customers.find(customer => customer.cliente_id_externo === valorIngresado);
    
    if (clienteEncontrado) {
      setErrorIdPropio('¡El ID Propio ya está en uso!');
    } else {
      setErrorIdPropio('');
    }
    setIdPropio(valorIngresado);
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const list = [...addresses];
    list[index][name] = value;
    setAddresses(list);
    const itemsChecked = list.every(item => item.celular && item.direccion && item.ciudad && item.departamento && item.pais);
    updateIsEnabled();
  };

  const handleAddAddress = () => {
    setAddresses([...addresses, { celular: '', direccion: '', ciudad: '', departamento: '', pais: '' }]);
    updateIsEnabled();
  };

  const handleRemoveAddress = (index) => {
    if (addresses.length === 1) return; // No permitir eliminar el único ítem
    const list = [...addresses];
    list.splice(index, 1);
    setAddresses(list);
    const itemsChecked = list.every(item => item.celular && item.direccion && item.ciudad && item.departamento && item.pais);
    updateIsEnabled();
  };

  const handleHoraMinChange = (e) => {
    const nuevaHoraMin = e.target.value;
    setHoraMin(nuevaHoraMin);
    if (nuevaHoraMin <= horaMax) {
      // setHoraMin(nuevaHoraMin);
      setHoraError(''); // Limpia el mensaje de alerta si la hora mínima es válida
      updateIsEnabled();
    } else {
      setHoraError('La hora mínima debe ser menor o igual a la hora máxima');
      updateIsEnabled(false);
    }
  };

  const handleHoraMaxChange = (e) => {
    const nuevaHoraMax = e.target.value;
    setHoraMax(nuevaHoraMax);
    if (nuevaHoraMax >= horaMin) {
      // setHoraMax(nuevaHoraMax);
      setHoraError(''); // Limpia el mensaje de alerta si la hora máxima es válida
      updateIsEnabled();
    } else {
      setHoraError('La hora máxima debe ser mayor o igual a la hora mínima');
      updateIsEnabled(false);
    }
  };

  useEffect(() => {
    updateIsEnabled();
  }, [nombre,idPropio]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <FormGroup className="col-md-6">
            <Label for="inputIdPropio">Id Propio</Label>
            <Input type="text" id="inputIdPropio" name="idPropio" value={idPropio} onChange={handleIdPropioChange} invalid={errorIdPropio !== ''} />
            <FormFeedback style={{ color: 'red' }}>{errorIdPropio}</FormFeedback>
          </FormGroup>
          <FormGroup className="col-md-6">
            <Label for="inputNombre">Nombre</Label>
            <Input type="text" id="inputName" name="nombre" onChange={(e) => setNombre(e.target.value)} />
          </FormGroup>
        </div>
        <div className="form-row">
          <FormGroup className="col-md-6">
            <Label for="inputHoraMinimaVisita">Hora Mínima Visita</Label>
            <Input type="time" id="inputHoraMinimaVisita" name="horaMinimaVisita" value={horaMin} onChange={handleHoraMinChange} />
          </FormGroup>
          <FormGroup className="col-md-6">
            <Label for="inputHoraMaximaVisita">Hora Máxima Visita</Label>
            <Input type="time" id="inputHoraMaximaVisita" name="horaMaximaVisita" value={horaMax} onChange={handleHoraMaxChange} />
          </FormGroup>
        </div>
        {horaError && <div className="text-danger">{horaError}</div>}
        {addresses.map((item, index) => (
          <div key={index}>
            <FormGroup>
              <Label for="inputPhone">Celular</Label>
              <Input type="tel" id="inputPhone" name="celular" value={item.celular} onChange={(e) => { handleInputChange(index, e) }} />
            </FormGroup>
            <div className="form-row">
              <FormGroup className="col-md-6">
                <Label for={`inputAddress${index}`}>Dirección</Label>
                <Input type="text" id={`inputAddress${index}`} name="direccion" value={item.direccion} onChange={(e) => { handleInputChange(index, e) }} />
              </FormGroup>
              <FormGroup className="col-md-2">
                <Label for={`inputCity${index}`}>Ciudad</Label>
                <Input type="text" id={`inputCity${index}`} name="ciudad" value={item.ciudad} onChange={(e) => { handleInputChange(index, e) }} />
              </FormGroup>
              <FormGroup className="col-md-2">
                <Label for={`inputState${index}`}>Departamento</Label>
                <Input type="text" id={`inputState${index}`} name="departamento" value={item.departamento} onChange={(e) => { handleInputChange(index, e) }} />
              </FormGroup>
              <FormGroup className="col-md-2">
                <Label for={`inputCountry${index}`}>País</Label>
                <Input type="text" id={`inputCountry${index}`} name="pais" value={item.pais} onChange={(e) => { handleInputChange(index, e) }} />
              </FormGroup>
              {addresses.length > 1 && (
                <Button color="danger" onClick={() => handleRemoveAddress(index)}>Eliminar</Button>
              )}
            </div>
          </div>
        ))}
        <Button color="primary" onClick={handleAddAddress}>+</Button>
        <div className="d-flex justify-content-between" style={{ marginTop: '20px' }}>
          <Button color="secondary" onClick={toggleCreateForm}>
            Cancelar
          </Button>{' '}
          <Button color="success" type="submit" disabled={!isEnabled}>
            Confirmar
          </Button>
        </div>
      </form>
    </>
  );
};

const CustomerDeleteForm = ({ customer, toggleDeleteForm, handleConfirmDelete }) => {
  const handleSubmit = (event) => {
    event.preventDefault(); // Evita que el formulario se envíe automáticamente
    toggleDeleteForm();
    handleConfirmDelete(customer.cliente_id);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <h4>
          ¿Está seguro/a que desea eliminar al cliente{" "}
          <span style={{ color: "#e14eca" }}>{customer.nombre}</span>
          {" "}y todos sus pedidos asociados?
        </h4>
        <div className="d-flex justify-content-between" style={{ marginTop: '20px' }}>
          <Button color="secondary" onClick={toggleDeleteForm}>
            Cancelar
          </Button>{' '}
          <Button color="danger" type="submit">
            Eliminar
          </Button>
        </div>
      </form>
    </>
  );
};

const CustomersInformation = ({ clientes, selectedCustomers, setSelectedCustomers, direccionesCliente, setSelectedAccuracys, selectedAccuracys,
  setSelectedPuntosIds, selectedPuntosIds, showButtons, setShowButtons, markerInitialPositions, setMarkerInitialPositions, markerPositions,
  setMarkerPositions, showPuntosNuevos, setShowPuntosNuevos, uploading, setUploading, numPages, actualPage, setActualPage, filtroNombre,
  setFiltroNombre, setIsPolygonMode, isPolygonMode }) => {

  const accuracyGroups = {};
  const [openMenus, setOpenMenus] = useState({});
  const [hoveredCards, setHoveredCards] = useState({});
  const [modalEdit, setModalEdit] = useState(false);
  const [modalCreate, setModalCreate] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [direccionClienteId, setDireccionClienteId] = useState(null);
  const [customerInformation, setCustomerInformation] = useState(null);
  const [customerInformationEdit, setCustomerInformationEdit] = useState(new Set());
  const [focused, setFocused] = useState(false);
  const menuRef = useRef(null);
  const accessToken = getDecryptedAccessToken();
  const navigate = useNavigate();

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        // Cerrar el menú si se hace clic fuera de él
        setOpenMenus({});
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  let filteredClientes = showPuntosNuevos
  ? clientes.filter(item => item.cliente_nuevo)
  : clientes;

  if (filtroNombre) {
    filteredClientes = filteredClientes
      .filter(item =>
        item.nombre.toLowerCase().includes(filtroNombre.toLowerCase()) ||
        item.cliente_id_externo.toLowerCase().includes(filtroNombre.toLowerCase())
      )
      .slice(0, 100);
  } else {
    filteredClientes = filteredClientes.slice(
      actualPage * 100,
      (actualPage + 1) * 100
    );
  };

  filteredClientes.forEach((item) => {
    item.color = '#5e72e4';
  });

  const clienteIdsSet = new Set(filteredClientes.map(cliente => cliente.cliente_id));

  let filteredData = showPuntosNuevos
    ? direccionesCliente.filter(item => item.cliente_nuevo)
    : direccionesCliente;

  filteredData = clienteIdsSet
    ? filteredData.filter(item => clienteIdsSet.has(item.cliente_id))
    : filteredData;

  filteredData.forEach((item) => {
    if (item.accuracy === '3') {
      item.color = 'green';
      item.accuracy_name = 'Ubicación precisa';
    } else if (item.accuracy === '2') {
      item.color = 'yellow';
      item.accuracy_name = 'Ubicación estimada';
    } else if (item.accuracy === 'MANUAL') {
      item.color = 'gray';
      item.accuracy_name= 'Ubicación manual';
    } else {
      item.color = 'red';
      item.accuracy_name = 'Ubicación poco precisa';
    }    
  });

  const handlePuntoIdClick = (punto_id) => {
    // const direccionesClienteFiltered = filteredData.filter(item => item.cliente_id === clienteId);
    // const listaPuntoId = new Set(direccionesClienteFiltered.map(item => item.punto_id));
    // setShowButtons(false);
  
    // if (selectedPuntosIds.size === 0) {
    //   setSelectedPuntosIds(listaPuntoId);
    // } else {
    //   setSelectedPuntosIds(new Set());
    // }
  
    // if (selectedCustomers.size === 0) {
    //   setSelectedCustomers(new Set([clienteId]));
    // } else {
    //   setSelectedCustomers(new Set());
    // }
    setShowButtons(false);

    if (selectedPuntosIds.size === 0) {
      setSelectedPuntosIds(new Set([punto_id]));
    } else {
      setSelectedPuntosIds(new Set());
    }
  };  

  const handlePuntosNuevosChange = () => {
    setShowPuntosNuevos(!showPuntosNuevos);
    setSelectedAccuracys(new Set());
    setSelectedPuntosIds(new Set());
  };

  const handleFiltroNombreChange = (event) => {
    setFiltroNombre(event.target.value);
  };

  const handleMenuClick = (cliente_id) => {
    setOpenMenus(prevState => ({
      ...prevState,
      [cliente_id]: !prevState[cliente_id]
    }));
  };

  const handleEditClick = (cliente_id) => {
    setOpenMenus(prevState => ({
      ...prevState,
      [cliente_id]: !prevState[cliente_id]
    }));
    const customerFinded = clientes.find(item => item.cliente_id === cliente_id);
    setCustomerInformation(customerFinded);
    setModalEdit(true);
  };

  const handleCreateClick = () => {
    setModalCreate(true);
  };

  const handleDeleteClick = (cliente_id) => {
    setOpenMenus(prevState => ({
      ...prevState,
      [cliente_id]: !prevState[cliente_id]
    }));
    const customerFinded = clientes.find(item => item.cliente_id === cliente_id);
    setCustomerInformation(customerFinded);
    setModalDelete(true);
  };

  const handleConfirmEdit = (cliente_id,nombre,horaMinimaVisita,horaMaximaVisita,addresses) => {
    setUploading(true);
    const apiUrl = `${config.apiBaseUrl}/EditarCliente/`;
    const postData = {
      'cliente_id':cliente_id,
      'nombreCliente':nombre,
      'horaMinVisitaCliente':horaMinimaVisita,
      'horaMaxVisitaCliente':horaMaximaVisita,
      'direcciones':addresses
    };
    // Verifica si se ha almacenado un token de acceso en localStorage
    if (!accessToken) {
      console.error('No se encontró un token de acceso en localStorage');
      return;
    }
  
    // Configura los encabezados para incluir el token de acceso como Bearer
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
  
    // Realiza la solicitud Axios con los encabezados configurados
    axios.post(apiUrl, postData, { headers })
      .then(response => {
        if (response.status === 200) {
          window.location.reload();
        } else if (response.status === 401) {
          console.error('Token de acceso inválido o expirado');
          navigate(`/login`);
          throw new Error('Token de acceso inválido o expirado');
        } else {
          console.error('Error en la respuesta del servidor:', response.data.message);
        }
      })
      .catch(error => {
        navigate(`/login`);
        console.error('Error al realizar la solicitud:', error);
      });
  };

  const handleConfirmCreate = (nombre,idPropio,horaMinimaVisita,horaMaximaVisita,direcciones) => {
    setUploading(true);
    const apiUrl = `${config.apiBaseUrl}/CrearCliente/`;
    const postData = {
      'nombreCliente':nombre,
      'idPropioCliente':idPropio,
      'horaMinVisitaCliente':horaMinimaVisita,
      'horaMaxVisitaCliente':horaMaximaVisita,
      'direccionesCliente':direcciones
    };
    // Verifica si se ha almacenado un token de acceso en localStorage
    if (!accessToken) {
      console.error('No se encontró un token de acceso en localStorage');
      return;
    }
  
    // Configura los encabezados para incluir el token de acceso como Bearer
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
  
    // Realiza la solicitud Axios con los encabezados configurados
    axios.post(apiUrl, postData, { headers })
      .then(response => {
        if (response.status === 200) {
          window.location.reload();
        } else if (response.status === 401) {
          console.error('Token de acceso inválido o expirado');
          navigate(`/login`);
          throw new Error('Token de acceso inválido o expirado');
        } else {
          console.error('Error en la respuesta del servidor:', response.data.message);
        }
      })
      .catch(error => {
        navigate(`/login`);
        console.error('Error al realizar la solicitud:', error);
      });
  };

  const handleConfirmDelete = (cliente_id) => {
    setUploading(true);
    const apiUrl = `${config.apiBaseUrl}/EliminarCliente/`;
    const postData = {
      'cliente_id':cliente_id
    };
    // Verifica si se ha almacenado un token de acceso en localStorage
    if (!accessToken) {
      console.error('No se encontró un token de acceso en localStorage');
      return;
    }
  
    // Configura los encabezados para incluir el token de acceso como Bearer
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
  
    // Realiza la solicitud Axios con los encabezados configurados
    axios.post(apiUrl, postData, { headers })
      .then(response => {
        if (response.status === 200) {
          window.location.reload();
        } else if (response.status === 401) {
          console.error('Token de acceso inválido o expirado');
          navigate(`/login`);
          throw new Error('Token de acceso inválido o expirado');
        } else {
          console.error('Error en la respuesta del servidor:', response.data.message);
        }
      })
      .catch(error => {
        navigate(`/login`);
        console.error('Error al realizar la solicitud:', error);
      });
  };

  const toggleEditForm = () => {
    // Alternar el estado del modal
    setModalEdit(!modalEdit);
  };
  
  const toggleCreateForm = () => {
    // Alternar el estado del modal
    setModalCreate(!modalCreate);
  };

  const toggleDeleteForm = () => {
    // Alternar el estado del modal
    setModalDelete(!modalDelete);
  };

  const handleCardMouseEnter = (cliente_id) => {
    setHoveredCards(prevState => ({
      ...prevState,
      [cliente_id]: true
    }));
  };

  const handleCardMouseLeave = (direccioncliente_id) => {
    setHoveredCards(prevState => ({
      ...prevState,
      [direccioncliente_id]: false
    }));
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const handleCustomerClick = (cliente_id) => {
    setSelectedCustomers(() => {
      const newSelectedCustomers = new Set(selectedCustomers);
      if (newSelectedCustomers.has(cliente_id)) {
        newSelectedCustomers.delete(cliente_id);
        setSelectedPuntosIds(new Set());
      } else {
        newSelectedCustomers.add(cliente_id);
      }
      return newSelectedCustomers;
    });
  };

  const goToFirstPage = () => {
    setActualPage(0);
  };

  const goToPrevPage = () => {
    setActualPage(Math.max(actualPage - 1, 0));
  };

  const goToNextPage = () => {
    setActualPage(Math.min(actualPage + 1, numPages - 1));
  };

  const goToLastPage = () => {
    setActualPage(numPages - 1);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div>
        <div className="title-container">
          <h2 className="text-center mb-3 text-white" style={{ paddingTop: '1rem', paddingBottom: '5px' }}>GESTIÓN CLIENTES</h2>
          <div className="divider-line"></div>
        </div>
        <Modal isOpen={modalEdit} toggle={toggleEditForm} modalClassName="modal-long modal-black" size="lg" > 
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={toggleEditForm}>
              <i className="tim-icons icon-simple-remove"></i>
            </button>
            <h5 className="modal-title">Editar Cliente</h5>
          </div>
          <ModalBody>
            <CustomerEditForm customer={customerInformation} customerAddresses={filteredData} toggleEditForm={toggleEditForm} handleConfirmEdit={handleConfirmEdit} />
          </ModalBody>
        </Modal>
        <Modal isOpen={modalCreate} toggle={toggleCreateForm} modalClassName="modal-long modal-black" size="lg">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={toggleCreateForm}>
              <i className="tim-icons icon-simple-remove"></i>
            </button>
            <h5 className="modal-title">Crear Cliente</h5>
          </div>
          <ModalBody>
            <CustomerCreateForm customers={clientes} toggleCreateForm={toggleCreateForm} handleConfirmCreate={handleConfirmCreate} />
          </ModalBody>
        </Modal>
        <Modal isOpen={modalDelete} toggle={toggleDeleteForm} modalClassName="modal-black">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={toggleDeleteForm}>
              <i className="tim-icons icon-simple-remove"></i>
            </button>
            <h5 className="modal-title">Eliminar Cliente</h5>
          </div>
          <ModalBody>
            <CustomerDeleteForm customer={customerInformation} toggleDeleteForm={toggleDeleteForm} handleConfirmDelete={handleConfirmDelete} />
          </ModalBody>
        </Modal>
        <Card className="mb-3" style={{ marginTop: '10px' }}>
          <CardHeader>
            <Nav className="justify-content-center">
                <NavItem>
                    <NavLink
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate('/admin/orders')}
                    >
                        Pedidos
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink disabled href="#">Clientes</NavLink>
                </NavItem>
            </Nav>
            <div className="form-row d-flex align-items-top">
              <div className="col-md-10">
                <InputGroup className={focused ? "input-group-focus" : ""}>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText><i className="tim-icons icon-zoom-split"></i></InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    placeholder="Buscar por id o nombre"
                    onChange={handleFiltroNombreChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                </InputGroup>
              </div>
              <div className="col-md-2">
                <Button className="btn-icon w-100" color="primary" onClick={() => setIsPolygonMode(!isPolygonMode)}>
                  <i className="fa-solid fa-draw-polygon"></i>
                </Button>
              </div>
            </div>
            <Button className="animation-on-hover w-100" color="info" title="Agregar Nuevo Cliente" onClick={() => handleCreateClick()}>
              Agregar Nuevo Cliente
            </Button>
          </CardHeader>
          <CardBody>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <FontAwesomeIcon icon={faAngleDoubleLeft} onClick={goToFirstPage} style={{ cursor: 'pointer' }} />
                <FontAwesomeIcon icon={faAngleLeft} onClick={goToPrevPage} style={{ cursor: 'pointer' }} />
              </div>
              <span>Página {actualPage + 1} de {numPages}</span>
              <div>
                <FontAwesomeIcon icon={faAngleRight} onClick={goToNextPage} style={{ cursor: 'pointer' }} />
                <FontAwesomeIcon icon={faAngleDoubleRight} onClick={goToLastPage} style={{ cursor: 'pointer' }} />
              </div>
            </div>
            {filteredClientes.map(item => (
                <Card key={item.cliente_id} className="mb-3" style={{ marginTop: '10px' }} onMouseEnter={() => handleCardMouseEnter(item.cliente_id)} onMouseLeave={() => handleCardMouseLeave(item.cliente_id)}>
                  <CardHeader style={{ display: 'flex', paddingBottom: '1rem', paddingTop: '1rem' }} >
                    <div style={{ display: 'flex', alignItems: 'center' }} ref={menuRef}>
                      {hoveredCards[item.cliente_id] && (
                        <>
                          <i className="fas fa-ellipsis-v" onClick={() => handleMenuClick(item.cliente_id)} onMouseDown={(e) => e.stopPropagation()} style={{ fontSize: '1.3rem', cursor: 'pointer' }} />
                        </>
                      )}
                      {openMenus[item.cliente_id] && (
                        <div style={{ position: 'absolute', top: 'calc(90%)', left: '0', backgroundColor: 'white', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.08)', zIndex: 9999 }}>
                          <div className='div_hover' style={{ padding: '8px', cursor: 'pointer' }} onClick={() => handleEditClick(item.cliente_id)} onMouseDown={(e) => e.stopPropagation()}>Editar</div>
                          <div className='div_hover' style={{ padding: '8px', cursor: 'pointer' }} onClick={() => handleDeleteClick(item.cliente_id)} onMouseDown={(e) => e.stopPropagation()}>Eliminar</div>
                        </div>
                      )}
                    </div>
                    <div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <i className="fa-solid fa-id-card" style={{ marginRight: '8px', color: item.color, fontSize: '0.8rem', marginLeft: '8px' }}></i>
                        <span>{item.cliente_id_externo}</span>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <i className="fa-solid fa-user" style={{ marginRight: '8px', color: item.color, fontSize: '0.8rem', marginLeft: '8px' }}></i>
                        <span>{item.nombre}</span>
                      </div>
                    </div>
                    <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', position: 'relative', cursor: 'pointer' }} onClick={() => handleCustomerClick(item.cliente_id)}>
                      <i className={`fas ${selectedCustomers.has(item.cliente_id) ? 'fa-chevron-up' : 'fa-chevron-down'}`} style={{ fontSize: '1rem', marginRight: '8px' }} />
                    </div>
                  </CardHeader>
                  <Collapse isOpen={selectedCustomers.has(item.cliente_id)}>
                    <CardBody style={{ paddingBottom: '1rem', paddingTop: '0.5rem' }}>
                      {filteredData
                        .filter(dataItem => dataItem.cliente_id === item.cliente_id)
                        .map(dirCli => (
                          <Card key={dirCli.direccioncliente_id} className="mb-2">
                            <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <i className="fa-solid fa-map-pin" style={{ marginRight: '8px', color: dirCli.color, fontSize: '1rem' }}></i>
                                <span>{dirCli.direccion}</span>
                              </div>
                              <div style={{ marginLeft: 'auto' }}>
                                <i className={`fas ${selectedPuntosIds.has(dirCli.punto_id) ? 'fa-solid fa-circle' : 'fa-regular fa-circle'}`} style={{ fontSize: '1rem', color: selectedPuntosIds.has(dirCli.punto_id) ? '#2bffc6' : 'inherit', cursor: 'pointer' }} onClick={() => handlePuntoIdClick(dirCli.punto_id)} />
                              </div>
                            </CardHeader>
                          </Card>
                        ))}
                    </CardBody>
                  </Collapse>
                  
                </Card>
            ))}
          </CardBody>
        </Card>
      </div>
    </DndProvider>
  );
};  
    
export default CustomersInformation;
